<template>
  <div class="settingManagement">
    <Jump :jumpList="jumpList" />
    <NavMenu :activeUrl="activeUrl" />
    <div class="page-wrap">
      <chil-menu/>
      <div class="detail-content">
        <div class="detail-content-container" style="margin-top: 2px">
          <div class="content-wrap">
            <div class="title-line">
              <p class="title">告警类型设置</p>
            </div>
            <div class="detail-wrap line-alignment">
              <span>告警类型</span>
              <el-checkbox-group
                      v-model="alarmTaskCheckList"
                      style="margin-left: 48px"
                      @change="changeAlarmTask"
              >
                <el-checkbox
                        v-for="(item, index) in alarmTask"
                        :key="index"
                        :label="item.publicKey"
                >{{ item.publicName }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="detail-content-container" style="margin-top: 2px">
          <div class="content-wrap">
            <div class="title-line">
              <p class="title">告警声音提醒设置</p>
            </div>
            <div class="detail-wrap line-alignment">
              <span>告警类型</span>
              <el-checkbox-group
                      v-model="alarmMusicCheckList"
                      style="margin-left: 48px"
                      @change="changeAlarmMusic"
              >
                <el-checkbox
                        v-for="(item, index) in alarmMusic"
                        :key="index"
                        :label="item.publicKey"
                >{{ item.publicName }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <!-- <div class="detail-content-container" style="margin-top:2px">
              <div class="content-wrap">
                  <div class="title-line">
                      <div class="title line-alignment">
                          <span>告警相似度配置</span>
                           <div class="tip">
                              <img src="@/assets/tip_icon.png">
                              <span>当抓拍到的照片与布控照片相似度达到预设值，则系统发起告警通知，通知方式包含站内声音告警、手机短信提醒。</span>
                          </div>
                      </div>
                  </div>
                  <div class="detail-wrap">
                      <div class="detail-line">
                          当抓拍人脸照片与布控照片相似度达
                          <el-input />% ~
                          <el-input />%（建议范围xx%-xx%)，则告警；
                      </div>
                      <div class="detail-line">
                          当抓拍车辆照片与布控照片相似度达
                          <el-input />% ~
                          <el-input />%（建议范围xx%-xx%)，则告警；
                      </div>
                      <div class="detail-line">
                          当抓拍摩托车照片与布控照片相似度达
                          <el-input />% ~
                          <el-input />%（建议范围xx%-xx%)，则告警；
                      </div>
                      <el-button type="primary" style="margin-top:25px">保 存</el-button>
                  </div>
              </div>
          </div>
          <div class="detail-content-container" style="margin-top:2px">
              <div class="content-wrap">
                  <div class="title-line">
                      <p class="title">布控告警消息接收者</p>
                  </div>
                  <div class="detail-wrap">
                      <el-button type="primary" icon="el-icon-plus" style="margin-top:25px">
                          新增接收人
                      </el-button>
                  </div>
              </div>
          </div> -->
      </div>
    </div>

  </div>
</template>

<script>
import NavMenu from "../navMenu.vue";
import Jump from "@/components/jump";
import { getAlarmTask, getAlarmMusic, setAlarm } from "@/api/setting";
import ChilMenu from "../chilMenu.vue";
export default {
  name: "settingManagement",
  components: {
    ChilMenu,
    Jump,
    NavMenu,
  },
  data() {
    return {
      activeUrl: "/setting/system",
      jumpList: [
        { name: "首页" },
        { name: "系统管理" },
        { name: "布控告警设置" },
      ],
      isActiveTabs: 0,
      option: [
        {
          tab: "布控告警设置",
        },
      ],
      alarmTaskCheckList: [],
      alarmMusicCheckList: [],
      alarmTask: [],
      alarmMusic: [],
    };
  },
  mounted() {
    this.getAlarmTask(), this.getAlarmMusic();
  },
  methods: {
    getAlarmTask() {
      getAlarmTask().then((res) => {
        this.alarmTask = res.data;
        this.alarmTask.forEach((task) => {
          if (task.publicValue == 1) {
            this.alarmTaskCheckList.push(task.publicKey);
          }
        });
      });
    },
    getAlarmMusic() {
      getAlarmMusic().then((res) => {
        this.alarmMusic = res.data;
        this.alarmMusic.forEach((music) => {
          if (music.publicValue == 1) {
            this.alarmMusicCheckList.push(music.publicKey);
          }
        });
      });
    },
    changeAlarmTask() {
      this.alarmTask.forEach((task) => {
        task.publicValue = "0";
        this.alarmTaskCheckList.forEach((check) => {
          if (task.publicKey === check) {
            task.publicValue = "1";
          }
        });
      });
      setAlarm(this.alarmTask);
    },
    changeAlarmMusic() {
      this.alarmMusic.forEach((music) => {
        music.publicValue = "0";
        this.alarmMusicCheckList.forEach((check) => {
          if (music.publicKey === check) {
            music.publicValue = "1";
          }
        });
      });
      setAlarm(this.alarmMusic);
    },
  },
};
</script>

<style lang="scss" scoped>
.settingManagement{
  padding: 0 10px;
}
.page-wrap {
  display: flex;
  .detail-content {
    background: transparent;
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
    width: 0;
  }
}
.detail-content {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  height: calc(100ch - 200px);
  position: relative;
  margin-top: 20px;
  & ::-webkit-scrollbar {
    display: none;
  }
  & ::v-deep .el-scrollbar__wrap {
    overflow: auto;
    height: 100%;
    margin: 0px !important;
  }
  & ::v-deep .el-table__empty-text {
    color: rgb(163, 214, 255);
  }
  &
    ::v-deep
    .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background: transparent;
  }
  & ::v-deep .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border: none;
  }
  & ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: transparent !important;
  }
  & ::v-deep .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
  }
  & ::v-deep .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: "";
    position: absolute;
    background-color: transparent;
    z-index: 1;
  }
  .detail-wrap {
    margin: 24px;
    & ::v-deep .single-row {
      font-family: "MicrosoftYaHei";
    }
    & ::v-deep .double-row {
      background: #0c265a !important;
      color: #ffffff;
      font-size: 14px;
      font-family: "MicrosoftYaHei";
      font-weight: 400;
    }
  }
  // .tabs-wrap{
  //     margin: 0;
  //     line-height: 48px;
  //     background: #0C275C;
  //     padding: 0 20px;
  //     // position: absolute;
  //     width: calc(100% - 41px);
  //     span{
  //         display: inline-block;
  //         padding: 0 20px;
  //         cursor: pointer;
  //         color: rgba(163,214,255,0.36);
  //         &.is-active{
  //             font-weight: bold;
  //             position: relative;
  //             color: #A3D6FF;
  //             &::after{
  //                 position: absolute;
  //                 content: '';
  //                 bottom: -2px;
  //                 left: 0;
  //                 right: 0;
  //                 height: 3px;
  //                 background: #0676ff;
  //             }
  //         }
  //     }
  // }
  .content-wrap {
    // background: rgba(12, 39, 92, 0.5);
    padding: 14px 0px;
    width: 100%;
    border-bottom: 10px solid #06193c;
    .title-line {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin-top: 8px;
      .title {
        color: #ffffff;
        position: relative;
        font-weight: bold;
        font-size: 16px;
        text-indent: 15px;
        font-family: "MicrosoftYaHeiUI";
        &::after {
          position: absolute;
          content: "";
          top: 2px;
          left: 0;
          width: 4px;
          height: 16px;
          background: linear-gradient(0deg, #1f79ff, #23b4ff);
        }
      }
    }
    .table-wrap {
      width: 1050px;
      th {
        min-width: 80px;
        padding: 5px 10px 10px 0px !important;
        background: #113275;
      }
      tr th {
        text-align: right;
        width: 300px;
        font-size: 14px;
      }
      tr td {
        width: 73rem;
        text-align: left;
      }
      tr th,
      tr td {
        border: 1px solid #21499a;
        padding: 10px;
      }
      .html-content {
        & ::v-deepa {
          text-decoration: underline !important;
          color: #0000ee !important;
        }
        & ::v-deep img {
          max-width: 100%;
        }
      }
    }
    .timeline-wrap {
      .alarmInformation-img {
        width: 64px;
        height: 80px;
        margin-left: 20px;
      }
      .alarmInformation-content {
        margin-left: 16px;
        font-size: 16px;
        .white {
          color: #ffffff;
          font-weight: bold;
        }
        .yellow {
          color: #f4ad49;
        }
      }
      .alarm-button {
        height: 12px;
        background: #0c265a;
        border: 1px solid #1d3b6a;
        border-radius: 2px;
        margin-top: 11px;
        display: flex;
        align-items: center;
        border: 1px solid #1d3b6a;
        padding: 6px 10px;
        color: #74c9fa;
        font-family: "MicrosoftYaHei";
        font-size: 12px;
        margin-right: 12px;
        cursor: pointer;
        img {
          margin-right: 5px;
        }
      }
    }

    & ::v-deep.el-timeline-item__node {
      width: 7px;
      height: 7px;
      background: #1a4a8b;
      border: 3px solid #3b98ff;
    }
    & ::v-deep .el-timeline-item__tail {
      left: 5px;
      border-left: 2px dotted #3b98ff;
    }
    & ::v-deep .el-timeline-item__timestamp {
      color: #74c9fa;
    }
  }
  a {
    span {
      color: #3399ff;
    }
  }
  .date-line {
    margin: 0px 0px 15px -10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      transform: rotate(90deg);
      width: 35px;
      height: 35px;
    }
    .date {
      font-weight: bold;
      color: #74c9fa;
      font-size: 16px;
      margin-right: 8px;
    }
  }
  .time-wrap {
    border-bottom: 1px solid #1d3b6a;
  }
  .date-wrap {
    display: flex;
    .wrap {
      padding: 0px 0px 18px 27px;
      margin-left: 30px;
      border-left: 1px dotted #1a4a8b;
      position: relative;
    }
    .alarm-detail {
      width: 719px;
      height: 104px;
      background: url("~@/assets/detail_alarm_bk.png");
    }
    .mark {
      position: absolute;
      top: -1px;
      left: -19px;
    }
  }
  .wrap-end {
    padding: 0px 0px 0px 27px !important;
    margin-bottom: 18px;
  }
}
.tip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
  img {
    width: 16px;
    height: 16px;
  }
  span {
    text-indent: 10px;
    font-size: 14px;
    color: #a3d6ff;
    font-weight: inherit;
  }
}
::v-deep .el-checkbox {
  color: #ffffff;
  .el-checkbox__inner {
    background-color: transparent;
    border-color: #496cb4;
  }
}
::v-deep .is-checked {
  .el-checkbox__inner {
    background-color: #339dff;
    border-color: #496cb4;
  }
  .el-checkbox__label {
    color: #ffffff;
  }
}
.el-input {
  width: 72px;
  margin: 0 8px;
  .el-input_inner {
    height: 36px;
    line-height: 36px;
  }
}
.detail-line {
  margin-top: 15px;
}
button {
  height: 36px;
  background: linear-gradient(0deg, #1f79ff, #23b4ff);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
